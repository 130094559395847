<template>
  <div v-if="contentNotFound">
    <!-- <categoriesPlaceholder v-if="!contentList" /> -->

		<Intersect v-if="loading && !contentList" @enter="loadCategory" >
      <categoriesPlaceholder :screen="screen" />
    </Intersect>
    <categorySlider
			v-else-if="showSlider"
      :screen="screen"
      :contentList="sliderContents"
      :screenName="screenName"
      :playerInstance="playerInstance"
    ></categorySlider>
  </div>
</template>

<script>
import categoriesPlaceholder from "@/components/placeholders/categoriesPlaceholder.vue";
import categorySlider from "@/components/categorySlider/categorySlider.vue";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Intersect from 'vue-intersect'
import { mapActions } from "vuex";



export default {
  props: {
    screen: {
      type: Object,
    },
    id: {
      type: Number,
    },
    screenName: {
      type: String,
    },
    localLang: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contentList: false,
      showSlider: true,
      sliderContents: [],
      localDisplayLang: null,
      isMpegRequired: false,
			loading: true,
			contentNotFound: true,
			trailersArr: []
    };
  },
  computed: {
    ...mapGetters(["getToken", "currentBrowser", "subscriberid"]),
  },
  watch: {
    getToken(val) {
      if (val) {
        this.loadCategory();
      }
    },
    subscriberid(val) {
      if (val) {
        this.loadCategory();
      }
    },
    screen(val) {
      console.log("slider screen changed");
      this.loadCategory();
    },
  },
  created() {
    console.log('INSIDE SLIDER',this.id,this.screen,this.screenName)
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //this.loadCategory();

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.loadCategory();
    });
  },
  mounted() {
    eventBus.$on(`${this.id}`, (response) => {
      if (!response.reason) {
				this.loading = false;
        this.contentList = true;
				console.log("SLIDER-CONTENTS",response)
        this.sliderContents = [...response.data];
				console.log("slider contents..." ,this.sliderContents);
        console.log('SLIDER CONTENTS',this.sliderContents)
      } else if (response.errorcode === 7109 || response.errorcode === 8812) {
        this.contentList = true;
        this.showSlider = false;
				 this.loading = true;
				 this.contentNotFound = false;
      }
    });
		   eventBus.$on("trailersResponse", (response) => {
				console.log("trailers-response",response);
				console.log("TRAILER-CONTENTS",response)
      if (!response.reason) {
				this.loading = false;
        this.contentList = true;
        this.sliderContents = [...response];
				console.log("trailer contents..." ,this.sliderContents);
        console.log('TRAILER CONTENTS',this.sliderContents)
      } else if (response.errorcode === 7109 || response.errorcode === 8812) {
        this.contentList = true;
        this.showSlider = false;
				 this.loading = true;
				 this.contentNotFound = false;
      }
    });
  },
  methods: {
		...mapActions(["listTrailers"]),
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
		getEndPoint () {
      const sectionType = this.screen.sectionType;
      const itemType = this.screen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      }  else {
         return "/subscriber/v1/content"
      }
    },
    loadCategory() {
			
			if(!this.loading){
        return;
      }
      if (this.screen.itemType === "TRAILERS") {
				//alert("in-trailers")
				console.log("CHECK-1",this.screen)
				 const trailerObjIds = [];
				 this.screen.sectionData.forEach(data => {
					console.log("SECTIONS-FOREACH",data.id)
					trailerObjIds.push(`"${data.id}"`);
					 this.trailersArr.push({
						itemlist: data.id
					 }) 
				});
				console.log("trailersArr",trailerObjIds)
        let payload = {
          itemlist: "["+trailerObjIds+"]"
        };

       // payload.params.page = 1;
				this.loading = true;
        this.contentList = false;
        this.showSlider = true;
				 eventBus.$emit("trailers-list", payload);
// 	 this.listTrailers(payload).then((data) => { 
// console.log("RESP",data)
// 	 });
      // eventBus.$emit("category-list", payload);
      }else {
				//alert("in-else-trailers")
				//debugger;
				let payload = {
          endpoint: this.screen.endpoint || this.getEndPoint(),
          id: this.id,
          params:  this.screen.parameters  || this.screen.sectionData || {}
        };

        let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }

        payload.params.page = 1;
				this.loading = true;
        this.contentList = false;
        this.showSlider = true;

        payload.params.displaylanguage = this.localDisplayLang;

        if (this.isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }

        if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
          payload.params.genre = "Kids";
        }

        eventBus.$emit("category-list", payload);
			}
    },
  },
  components: {
		Intersect,
    categoriesPlaceholder,
    categorySlider,
  },
};
</script>

<style></style>
