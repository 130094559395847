<template>
  <div>
    <section class="container">
      <!--slider-header-container-->
      <div class="slider-header" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="slider-title">
          <span class="title">{{ localDisplayLang === "ara" ? screen.title.ara : screen.title.default }}</span>
          <!-- <span
            class="seeall"
            @click="seeAll(screen)"
            v-if="contentList.length >= appConfig.defaultpageSize || (screen.seeAllLink && contentList.length > 4)"
            >{{ $t("see all") }}</span
          > -->
        </div>
        <div class="slider-head">

          <span
            class="seeall"
            @click="seeAll(screen)"
            v-if="contentList.length >= appConfig.defaultpageSize || (screen.seeAllLink && contentList.length > 4)"
            >{{ $t("see all") }}</span
          >

          <div class="slider-head-btns">
            <div :id="`controls-${sliderName}`" class="slider-controls" :dir="localDisplayLang === 'ara' ? 'ltr' : ''">
              <!--previous-arrow-->
              <button type="button">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
              </button>
              <!--next-arrow-->
              <button type="button">
                <img src="@/assets/icons/more-than-filled-50.png" width="30px" alt="more" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--slider-carousel-cards-container-->
      <div :id="`slide-${sliderName}`" class="slider-content" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div v-for="(content, index) in contentList" :key="index" class="content-slider" @click="clickHandler(content)">
          <categoryCard
            :screen="screen"
            :content="content"
            :displayTags="true"
            :isDirectPlay="content.objecttype === 'CONTENT' && content.category !== 'MOVIE' ? true : false"
            :playerInstance="playerInstance"
            :showTitle="isTitleEnabled(content)"
            :cardType="fetchCardType(content)"
            :id="`${index}-${sliderName}`"
            @selection="(payload) => lightUp(payload)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { tns } from "tiny-slider/src/tiny-slider";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
//import { store } from "@/store/store";
import Utility from "@/mixins/Utility.js";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";


export default {
  props: {
    screen: {
      type: Object,
    },
    contentList: {
      type: Array,
    },
    screenName: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
    listType: {
      type: String,
    },
  },
  data() {
    return {
      contents: [],
      detailContent: null,
      toggleDetail: null,
      toggleDetailPopup: null,
      differStyles: null,
      cardDisplayType: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["categorySelected", "appConfig", "getRtl", "subscriberid", "availabilityList", "subscriptionList"]),
    //creating slidername for carousel controls and container.
    sliderName() {
      return `${this.screen.title.default}`.replace(/[\s&]+/g, "_");
    },
  },
  watch: {
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      // this.fetchCardType();
    });

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.contentList && this.contentList.length <= 7) {
        let dom = document.getElementById(`slide-${this.sliderName}-iw`);
        dom.style.marginLeft = "5px";
      }

      if (this.localDisplayLang === "ara" && this.contentList && this.contentList.length < 7) {
        document.getElementById(`slide-${this.sliderName}-iw`).style.textAlign = "-webkit-right";
        document.getElementById(`slide-${this.sliderName}-iw`).style.textAlign = "-moz-right";
      }
    }, 100);

    this.constructSlider();

    eventBus.$on("detail-close", () => {
      this.detailContent = null;
      this.toggleDetailCard(false);
    });

    eventBus.$on(`${this.sliderName}`, (content) => {
      this.detailContent = content;
      this.toggleDetailCard(true);
    });
  },
  methods: {
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    isTitleEnabled(content) {
      if (content.hasOwnProperty("objecttype")) {
        return content.objecttype === "CONTENT" ? true : false;
      } else {
        return false;
      }
    },
    lightUp(payload) {
      if (screen.width > "900") {
        eventBus.$emit("off");
        eventBus.$emit(`highlight-${payload.id}`);
        this.detailContent = payload.content;
        this.toggleDetailCard(true);
      } else {
        let contentid = payload.content.objectid;
        this.$router.push({ name: `detail`, params: { contentid } });
      }
    },
    seeAll(screen) {
      console.log('SEE ALL CLIVKED=========',screen)
      let currentLanguage = this.getCurrentLanguageUrlBase();
			let screenData = JSON.stringify(screen);
			localStorage.setItem("screen-list-collection", screenData);

      if (screen.hasOwnProperty("seeAllLink") && screen.seeAllLink) {
				let section = this.spacialCharEncoding(screen.seeAllLink)
       // let section = screen.seeAllLink;
				//debugger;
        this.$router.push({ name: "screen", params: { section: section.toLowerCase(), routeObject: screen, lang: currentLanguage } });
      } else {
				let titleDefault = this.spacialCharEncoding(screen.title.default);
        //let titleDefault = screen.title.default.replace(" ", '-')
        this.$router.push({
          name: "screen",
          params: { section: titleDefault.toLowerCase(), routeObject: screen, lang: currentLanguage },
        });
      }
    },
    toggleDetailCard(state) {
      this.toggleDetail = state;
    },
    closeDetailPopup(state) {
      this.toggleDetailPopup = state;
    },
    constructSlider() {
      let data = {
        container: `#slide-${this.sliderName}`,
        controlsContainer: `#controls-${this.sliderName}`,
        nav: false,
        loop: false,
        slideBy: "page",
        gutter: 15,
        responsive: {
          576: {
            edgePadding: 5,
            gutter: 5,
            items: 1,
          },
          700: {
            items: 2,
            edgePadding: 5,
            gutter: 5,
          },
          1050: {
            edgePadding: 5,
            gutter: 5,
            items: 4,
          },
          1200: {
            edgePadding: 5,
            gutter: 5,
            items: 4,
          },
          1900: {
            edgePadding: 5,
            gutter: 15,
            items: 4.5,
          },
        },
      };
      if (this.screen.displayType === "SQUARE" || this.screen.displayType === "PORTRAIT") {
        data.responsive = {
          // 320: {
          //   items: 2.3,
          //   edgePadding: 5,
          //   gutter: 10,
          // },
          // 400: {
          //   items: 2.7,
          //   edgePadding: 5,
          //   gutter: 7,
          // },
          // 576: {
          //   items: 3,
          //   edgePadding: 5,
          //   gutter: 5,
          // },
          // 768: {
          //   items: 3.5,
          //   edgePadding: 5,
          //   gutter: 5,
          // },
          // 901: {
          //   edgePadding: 5,
          //   gutter: 10,
          //   items: 5.5,
          // },
          // 1040: {
          //   edgePadding: 5,
          //   gutter: 15,
          //   items: 6.5,
          // },
          // 1281: {
          //   edgePadding: 5,
          //   gutter: 10,
          //   items: 7.2,
          // },
          // 1350: {
          //   edgePadding: 5,
          //   gutter: 15,
          //   items: 6.2
          // },
          // 1650: {
          //   edgePadding: 5,
          //   gutter: 15,
          //   items: 7.9
          // },
          // 2200: {
          //   edgePadding: 5,
          //   gutter: 15,
          //   items: 10.5
          // },

          320: {
            items: 3,
            edgePadding: 5,
            gutter: 10,
          },
          400: {
            items: 3,
            edgePadding: 5,
            gutter: 7,
          },
          576: {
            items: 3,
            edgePadding: 5,
            gutter: 5,
          },
          768: {
            items: 4,
            edgePadding: 5,
            gutter: 5,
          },
          900: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1024: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },
          1200: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },

          1280: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          1290: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },

          1366: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          1440: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          1600: {
            edgePadding: 5,
            gutter: 15,
            items: 7,
          },
          1680: {
            edgePadding: 5,
            gutter: 15,
            items: 7,
          },
          1920: {
            edgePadding: 5,
            gutter: 15,
            items: 8,
          },
          2200: {
            edgePadding: 5,
            gutter: 15,
            items: 9,
          },
          2400: {
            edgePadding: 5,
            gutter: 15,
            items: 10,
          },
        };
      } else if (this.screen.displayType === "LANDSCAPE") {
        data.responsive = {
          320: {
            items: 2,
            edgePadding: 5,
            gutter: 7,
          },
          576: {
            items: 2,
            edgePadding: 5,
            gutter: 7,
          },
          600: {
            edgePadding: 5,
            gutter: 15,
            items: 2,
          },
          768: {
            edgePadding: 5,
            gutter: 15,
            items: 3,
          },
          900: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1024: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1200: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1280: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1366: {
            edgePadding: 5,
            gutter: 15,
            items: 4,
          },
          1440: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },
          1600: {
            edgePadding: 5,
            gutter: 15,
            items: 5,
          },
          1680: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          1920: {
            edgePadding: 5,
            gutter: 15,
            items: 6,
          },
          2200: {
            edgePadding: 5,
            gutter: 15,
            items: 7,
          },
          2400: {
            edgePadding: 5,
            gutter: 15,
            items: 8,
          },
        };
      }
      this.$nextTick(() => {
        this.slider = tns(data);

        if (this.localDisplayLang === "ara") {
          this.slider.goTo("last");
        } else {
          this.slider.goTo("first");
        }
      });
    },
    fetchCardType(content) {
      if (screen.width <= 375) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              playButton: {
                enablePlay: content.objecttype === "CONTENT" ? true : false,
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
              width: "160px",
              playButton: { enablePlay: false },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "150px",
              width: "105px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 375 && screen.width <= 425) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "100px",
              width: "170px",
              playButton: {
                enablePlay: content.objecttype === "CONTENT" ? true : false,
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "170px",
              width: "110px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 425 && screen.width <= 800) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "147px",
              width: "240px",
              playButton: { enablePlay: false },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "260px",
              width: "180px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 800 && screen.width < 1050) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "120px",
              width: "210px",
              playButton: { enablePlay: false },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "220px",
              width: "150px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else if (screen.width > 1200 && screen.width < 1350) {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "140px",
              width: "270px",
              playButton: { enablePlay: false },
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "220px",
              width: "150px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      } else {
        return this.screen.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "150px",
              width: "260px",
              playButton: {
                enablePlay: content.objecttype === "CONTENT" ? true : false,
                width: "40px",
                height: "40px",
              },
              displayTitle: true,
            }
          : this.screen.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "300px",
              width: "200px",
              playButton: { enablePlay: false },
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false },
            };
      }
    },
    clickHandler(content) {
			// alert("clickHandler-categorySlider")
			// debugger;
			console.log("CLICKHANDLER",content)
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (content.objecttype !== "CONTENT" || content.category === "MOVIE") {
        if (screen.width > 0) {
          //this.openDetailPopup(content);
          if (content && content.category == "TVSHOW") {
            // let title;
            // if (this.localDisplayLang == "eng") {
            //   title = item.title.replace(/\s/g, "-");
            // } else {
            //   title = "title";
            // }
             let title = content.defaulttitle.replace(/\s/g, "-");
            this.$router.push({
              name: "detailPage",
              params: { contentId: content.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage.toLowerCase() },
            });
          }else if(content.trailertype == "TRAILER"){
				this.showPlayer();
        // this.loadContentMetaData(this.contentDetails, null);
        // this.contentDetails.isDrmContent = false;
        content.trailerUrl = content.filelist[0].filename;

        // let locationArr = location.pathname.split("/");

        // window.history.pushState(
        //   "object or string",
        //   {},
        //   "/" +
        //     locationArr[1] +
        //     "/" +
        //     locationArr[2] +
        //     "/" +
        //     locationArr[3] +
        //     "/trailer/" +
        //     content.trailerid +
        //     "/play"
        // );

        let contentList = [content];
        this.loadContentMetaData(contentList[0], null);
      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
					}
					 else {
            // let title;
            // if (this.localDisplayLang == "eng") {
            //   title = item.title.replace(/\s/g, "-");
            // } else {
            //   title = "title";
            // }
             let title = content.defaulttitle.replace(/\s/g, "-");
            this.$router.push({
              name: "detailPageMovie",
              params: { mediaTitle: title.toLowerCase(), contentId: content.objectid.toLowerCase(), lang: currentLanguage.toLowerCase() },
            });
          }
        } else {
          //this.$router.push({ name: "detail", params: { contentid: content.objectid } });
        }
      }
    },
		showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
      this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categorycard" */ "@/components/Templates/categoryCard.vue"),
    detailCard: () => import(/* webpackChunkName: "detailcard" */ "@/components/Templates/detailCard.vue"),
  },
  beforeDestroy() {
    // eventBus.$off("check-local-storage");

    if (this.slider) {
      this.slider.destroy();
    }
  },
  mixins: [Utility, PlaybackUtilities],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
.container {
  .slider-header {
    margin-top: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    .slider-title {
      display: flex;

      .title {
        color: $clr-light-gd5;
        font-family: $font-regular;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 1.5rem;
      }
    }
      .seeall {
        cursor: pointer;
        color: #848486;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        line-height: 1.5rem;
        padding: 0 5px;
        transition: 0.1s;
        align-self: center;
        // margin-left: 20px;
        &:hover {
          transform: scale(1.05);
          color: $clr-light-gd3;
        }
      }
    .slider-head {
      // margin-bottom: 5px;
      // display: grid;
      // justify-content: end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .slider-head-btns {
        margin-top: 3px;
        .slider-controls {
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 1.6rem;
              width: auto;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .slider-content {
    margin-top: 1rem;
    .content-slider {
      &:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .container {
    .slider-header {
      .slider-title {
        // width: 1080px;
        display: flex;
        .title {
          font-size: 0.9rem;
        }
      }
      .seeall {
        font-size: 0.9rem;
      }
      .slider-head {
        width: 60%;
        .slider-head-btns {
          margin-top: 3px;
          .slider-controls {
            button {
              border: none;
              background: transparent;
              cursor: pointer;
              img {
                height: 0.9rem;
                width: auto;
              }
              &:disabled {
                opacity: 0.3;
              }
              &:focus {
                outline: none;
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
</style>
